import {IControllerProps} from "../../types/app.types";
import {
  BaseController,
} from '@wix/wixstores-client-storefront-sdk';
import { wixcodePublicApi } from './WixCode/wixcodePublicApi';

export class CheckoutController extends BaseController {
  private readonly services = {};

  private readonly updateComponent = async () => {
    this.setProps({
      isLoading: false,
    } as IControllerProps);
  };

  public readonly load = async (): Promise<void> => {
    this.setProps({ isLoading: true });
    await new Promise(function (resolve) {
      setTimeout(resolve, 3000);
    });
    await this.updateComponent();
  };

  public readonly init = async (): Promise<void> => {
    await this.updateComponent();
  };

  public getFreeTexts(): string[] {
    return [];
  }

  public exports() {
    return wixcodePublicApi();
  }

  onStyleUpdate(_styleParams: {}) {}
}
